import React, {useEffect} from "react";

import {
  Container,
  SubContainerContent3,
  Cards
} from "../styles/index";

import {
  MainContainerStyle,
  SocialMediaIconContainer,
  SocialMediaIcon
} from "../globalStyles";

import * as Image0 from "../images/HomePage/FirstPCBuild.jpg";
import * as Image1 from "../images/HomePage/PaperAndPen.jpg";
import * as Image2 from "../images/HomePage/DesigningImg.jpg";
import * as Image3 from "../images/HomePage/CodeABckground2.jpg";

import * as Github from "../images/Commons/github.svg";
import * as Instagram from "../images/Commons/instagram.svg";
import * as Linkedin from "../images/Commons/linkedin.svg";

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      age: 0,
    }
  }

  componentDidMount() {
    var birth = new Date("05/08/2003");
    var get_difference = Date.now() - birth.getTime();

    var pre_age = new Date(get_difference); 
    var age_year = pre_age.getUTCFullYear();
    
    //now calculate the age of the user
    var age = Math.abs(age_year - 1970);
    this.setState({age})
  }

  render(){
    return(
      <>
        <Container>
          <MainContainerStyle BackgroundImage={Image0}>
            <div
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-easing="ease">
            </div>
            <article
              data-sal="slide-left"
              data-sal-delay="200"
              data-sal-easing="ease">
              <title>
                Hello world
              </title>
              Olá! Como escrito no cabeçalho, sou Victor Gomez. Sou um aluno Brasileiro de {this.state.age} anos.
              <br/><br/>
              Estou iniciando em ciência da computação, e me apaixonei pelo assunto/matéria e aprendo cada dia mais sobre a área.
              <br/><br/>
              Atualmente faço sites e aplicativos (frontend e backend), assim como o design. Para isso uso as tecnologias React (e derivados), NodeJS, ExpressJS, Adonis, MongoDB, MySQL, Kubernetes e outros ... E pretendo continuar fazendo isso no futuro, pois simplesmente sou apaixonado por desenvolvimento de software e ciencia da computação
            </article>
          </MainContainerStyle>
          <SocialMediaIconContainer>
            <title>
              Encontre me:
            </title>
            <sub>
              <SocialMediaIcon
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                href="https://github.com/Vicg853"> 
                <Github/>
              </SocialMediaIcon>
              <SocialMediaIcon
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                href="https://www.instagram.com/victor_g853/"> 
                <Instagram/>
              </SocialMediaIcon>
              <SocialMediaIcon
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                href="https://www.linkedin.com/in/victor-rosa-gomez-15953a171/"> 
                <Linkedin/>
              </SocialMediaIcon>
            </sub>
          </SocialMediaIconContainer>
          <SubContainerContent3>
            <title>Execução dos meus projetos:</title>
            <sub>
              <Cards
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                ImageUrl={Image1}>
                <div></div>
                <h1>
                  <span>1)</span> Planejamento:
                </h1>
                <article>
                  Primeiramente eu planejo tudo, do zero. Vejo o que será necessário utilizar e fazer. 
                  <br/>
                  Defino por exemplo: Cada página de um site terá e fara, rotas etc...
                </article>
              </Cards>
              <Cards
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
               ImageUrl={Image2}>
                <div></div>
                <h1>
                  <span>2)</span> Prototipação:
                </h1>
                <article>
                  Faço uma parte do design da aplicação/site/etc...
                  <br/>
                  Para demonstração e aprovação. E mostro todo o planejamento de modo compreensível.
                </article>
              </Cards>
              <Cards
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                ImageUrl={Image3}>
                <div></div>
                <h1>
                  <span>3)</span> Desenvolvimento:
                </h1>
                <article>
                  Começo então o desenvolvimento completo, que poderá demorar um certo tempo. Faço então um teste e verifico se tudo está funcionando corretamente, para então lançar no ar a aplicação/site/etc.
                </article>
              </Cards>
            </sub>
          </SubContainerContent3>
        </Container>
      </>
    );
  }
}