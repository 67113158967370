import styled from "styled-components";

import { 
    Theme,
    RootContainerStyle
} from "../globalStyles";

export const Container = styled.div`
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SubContainers = styled.div`
    width: calc(100vw - 140px);
    max-width: 1150px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-top: 50px;
    margin-bottom: 10px;
    @media screen and (max-width: 1000px){
        width: calc(100vw - 70px);
    }
`;

export const SubContainerContent3 = styled(RootContainerStyle)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
    title{
        display: block;
        font-size: 20px;
        font-weight: bold;
        color: ${Theme.accent1};
        ::after{
            display: block;
            content: "";
            width: 70px;
            height: 3px;
            background-color: ${Theme.accent3};
            margin-top: 10px;
        }
    }
    sub{
        display: inline-flex;
        justify-content: space-between;
        margin-top: 25px;
        width: 100%;
    }
    @media screen and (max-width: 1200px){
        width: calc(100vw - 50px);
    }
    @media screen and (max-width: 1050px){
        sub{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }
    @media screen and (max-width: 490px){
        width: calc(100vw - 15px);
    }
`;

export const Cards = styled.div`
    width: calc(310px + 2rem);
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: ${Theme.accent2};
    padding: 1rem;
    border-radius: ${Theme.borderRadius};
    div{
        width: 310px;
        height: 200px;
        background-image: url("${props => props.ImageUrl}");
        background-size: cover;
        background-position: center center;
        border-radius: ${Theme.borderRadius};
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7);
    }
    h1{
        font-size: 19px;
        font-weight: 700;
        color: ${Theme.accent1};
        margin-top: 32px;
        margin-bottom: 7px;
        align-items: center;
        span{
            color: ${Theme.accent3};
            font-size: 17px;
        }
    }
    article{
        display: block;
        font-size: 15.7px;
        font-weight: 300;
        color: ${Theme.accent1};
        opacity: 0.85;
        text-justify: inter-word;
    }
    @media screen and (max-width: 1110px){
        width: calc(290px + 2rem);
        div{
            width: 290px;
        }
    }
    @media screen and (max-width: 1050px){
        width: calc(310px + 2rem);
        margin: 15px;
        margin-top: 10px;
        div{
            width: 310px;
        }
    }
    @media screen and (max-width: 490px){
        width: 100%;
        height: auto;
        margin: 0px;
        margin-top: 20px;
        div{
            width: 100%;
        }
    }
`;